import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppStorageService } from './app.storage';
import { LanguageId } from './app.interface';
import { environment } from '../environments/environment';
import { finalize } from 'rxjs/operators';

@Injectable()
export class AppGlobalService {
  requestOptions = { withCredentials: true };
  kong: string;
  bffPortal: string;
  userState: any;
  yodaPartner: String;

  constructor(private http: HttpClient, private appStorage: AppStorageService) {
    this.kong = environment.host + '/gateway';
    this.bffPortal = this.kong + '/bff/portal';
    this.yodaPartner = environment.host + environment.gateway + '/yoda-partner';
  }

  /*
    GLOBAL HTTP REQUESTS...
  */

  getBffPortal(_endpoint): any {
    return this.http.get(this.bffPortal + _endpoint, this.requestOptions);
  }

  postBffPortal(_endpoint, _data = null) {
    return this.http.post(this.bffPortal + _endpoint, _data, this.requestOptions);
  }

  putBffPortal(_endpoint, _data = null) {
    return this.http.put(this.bffPortal + _endpoint, _data, this.requestOptions);
  }

  putPortal(_endpoint, _data = null) {
    return this.http.put(this.kong + _endpoint, _data, this.requestOptions);
  }

  getYodaPartner(_endpoint) {
    return this.http.get(this.yodaPartner + _endpoint, this.requestOptions);
  }

  /*
    GLOBAL UTILS FUNCTIONS...
  */

  getPageLanguage(route: ActivatedRouteSnapshot): LanguageId {
    const isXborder = /interna(c|t)ional/.test(route.routeConfig.path);
    if (isXborder) {
      return route.params['langId'] || 'en';
    }
    return 'pt';
  }

  doLogout() {
    this.http.post(environment.host + '/gateway/logout', null, this.requestOptions).pipe(
      finalize(() => {
        this.appStorage.removeUserData();
        window.location.href = '/login';
      })
    );
  }
}
