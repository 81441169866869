export class HeaderTranslate {
  pt = {
    linkTitle: 'Americanas Marketplace - Homepage link',
    logoDescription: 'logo Americanas Marketplace',
    go: 'Ir ',
    goTo: 'Ir para ',
    goToRegistration: 'Ir para o Cadastro',
    titleAcessMenu: 'Clique para acessar o Menu',
    buttonSell: 'quero vender',
    forYourBusiness: 'para o seu negócio',
    howToSell: 'como vender',
    doubts: 'dúvidas',
    login: 'login',
  };
  en = {
    linkTitle: 'Americanas Marketplace - Homepage link',
    logoDescription: 'brand Americanas Marketplace',
    go: 'Go ',
    goTo: 'Go to ',
    goToRegistration: 'Go to Registration',
    titleAcessMenu: 'Click to access the Menu',
    buttonSell: 'start now',
    forYourBusiness: 'for your business',
    howToSell: 'how to sell',
    doubts: 'faq',
    login: 'login',
  };
  zh = {
    buttonSell: '现在开始',
    forYourBusiness: '为您的业务',
    howToSell: '怎么卖',
    doubts: '常见问题',
    login: '登录',
    linkTitle: 'Americanas Marketplace - Homepage link',
    logoDescription: 'brand Americanas Marketplace',
    go: 'Go ',
    goTo: 'Go to ',
    goToRegistration: 'Go to Registration',
    titleAcessMenu: 'Click to access the Menu',
  };
}
