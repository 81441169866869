import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  page = {
    error: 404,
    message: 'Ops! Página não encontrada ;(',
  };

  constructor(private activeRoute: ActivatedRoute) {}

  ngOnInit() {
    if (this.activeRoute.snapshot.queryParams['forbidden']) {
      this.page.error = 403;
      this.page.message = 'Ops! Usuário sem permissão de acesso ;(';
    }
  }
}
