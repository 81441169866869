import { GoogleAnalyticsService } from './google-analytics.service';
import { Component } from '@angular/core';
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(private router: Router, private googleAnalyticsService: GoogleAnalyticsService) {
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
      if (event instanceof NavigationEnd) {
        this.googleAnalyticsService.ga({
          event: 'page_view',
        });

        this.googleAnalyticsService.addGtmToDom();
      }
    });
  }

  ngOnInit() {
    this.checkUrl();
    this.checkUrlParams();
    this.checkRedirects();
  }

  getEvents(url) {
    url = decodeURIComponent(url);
    let event = null;
    let event_name = null;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      event = httpParams.get('event');
      event_name = httpParams.get('event_name');
    }
    return {
      event,
      event_name,
    };
  }

  /** Intercepta todas as navegações via rota da aplicalção */
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      // document.getElementById('main').classList.add('loading');
      const events = this.getEvents(event.url);
      if (events.event !== null) {
        this.googleAnalyticsService.ga({
          event: events.event,
          event_name: events.event_name,
        });
      }
    }
    if (event instanceof NavigationEnd) {
      this.resetAppStyles();
    }
    if (event instanceof NavigationCancel) {
      this.resetAppStyles();
    }
    if (event instanceof NavigationError) {
      this.resetAppStyles();
    }
  }

  checkRedirects() {
    const url = document.location;
    switch (url.hash) {
      case '#/itens/meus':
        window.location.href = '/v2/#/itens/meus';
        return;
      case '#/financeiro/contacorrente':
        window.location.href = '/v3/financeiro/conta-corrente';
        return;
      case '#/chamados':
        window.location.href = '/v3/ajuda/chamados';
        return;
      case '#/chamados-abrir':
        window.location.href = '/v3/ajuda/chamados/novo';
        return;
      case '#/cadastro-parceiro':
        window.location.href = '/v3/cadastro-seller/dados-parceiro';
        return;
      case '#/cadastro-parceiro/como-funciona':
        window.location.href = '/v3/como-funciona';
        return;
      case '#/webstore/usuarios/todos':
        window.location.href = '/v3/configuracoes/usuarios';
        return;
      case '#/frete/conf':
        window.location.href = '/v3/configuracoes/frete';
        return;
      case '#/pedidos/comentarios':
        window.location.href = '/v3/pedidos/avaliacoes';
        return;
      case '#/itens/upload-new':
        window.location.href = '/v3/itens/upload';
        return;
      case '#/pedidos/forasla':
        window.location.href = '/v3/pedidos/fora-sla';
        return;
    }
    switch (url.pathname) {
      case '/app':
        window.location.href = '/v3/app';
        return;
    }
  }

  checkUrlParams() {
    const urlParams = window.location.search.replace('?', '');
    if (urlParams.includes('epar') || urlParams.includes('utm')) {
      sessionStorage.setItem('registerCampaign', JSON.stringify({ campaignId: urlParams }));
    }
  }

  checkUrl() {
    const baseUrl = environment.production ? environment.oldHost : window.location.origin;
    const isCorrectPortalDomain = window.location.href.includes('portal.') && window.location.pathname === '/';

    if (environment.production && isCorrectPortalDomain) {
      window.location.href = `${baseUrl}/login${window.location.search}`;
    }
  }

  resetAppStyles() {
    $('.modal-backdrop').remove();
    setTimeout(() => {
      // document.getElementById('main').classList.remove('loading');
      $('html, body').animate({ scrollTop: 0 }, 600);
      $('body').removeClass('modal-open');
    }, 500);
  }
}
