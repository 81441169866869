import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppStorageService } from './app.storage';
declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsService {
  private isLoaded = false;
  private browserGlobals = {
    windowRef(): any {
      return window;
    },
    documentRef(): any {
      return document;
    },
  };

  constructor(private appStorage: AppStorageService, private route: ActivatedRoute) {}

  public addGtmToDom(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.isLoaded) {
        return resolve(this.isLoaded);
      }
      const doc = this.browserGlobals.documentRef();
      const gtm = 'GTM-5MKNTVL';
      this.pushOnDataLayer({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });

      const gtmScript = doc.createElement('script');
      gtmScript.id = 'GTMscript';
      gtmScript.async = true;

      gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtm}`;
      gtmScript.addEventListener('load', () => {
        return resolve((this.isLoaded = true));
      });
      gtmScript.addEventListener('error', () => {
        return reject(false);
      });
      doc.head.insertBefore(gtmScript, doc.head.firstChild);
    });
  }

  public getDataLayer(): any[] {
    const window = this.browserGlobals.windowRef();
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  }

  private pushOnDataLayer(obj: object): void {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }

  public pushTag(item: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.pushOnDataLayer(item);
      return resolve();
    });
  }

  public ga(params) {
    let epar_consultor = 'não';
    if (this.route.snapshot?.queryParams?.epar ? true : false) {
      epar_consultor = this.route?.snapshot?.queryParams?.epar?.toLowerCase().includes('consultor') ? 'sim' : 'não';
    }
    if (this.appStorage.getUserData().partnerId) {
      this.pushTag({
        event_name: params.event_name ? params.event_name : null,
        event: params.event,
        cnpj: this.appStorage.getUserData().partnerId ? this.appStorage.getUserData().partnerId : null,
        msg_de_erro: params.msg_de_erro ? params.msg_de_erro : null,
        tipo_de_cadastro: params.tipo_de_cadastro ? params.tipo_de_cadastro : null,
        cargo: params.cargo ? params.cargo : null,
        seller: params.seller ? params.seller : null,
        tipo_de_chamado: params.tipo_de_chamado ? params.tipo_de_chamado : null,
        tipo_de_login: params.tipo_de_login ? params.tipo_de_login : null,
        tipo_de_credito: params.tipo_de_credito ? params.tipo_de_credito : null,
        chave: this.route.snapshot.queryParams['chave']
          ? this.route.snapshot.queryParams['chave']
          : null,
        epar: this.route.snapshot.queryParams['epar']
          ? this.route.snapshot.queryParams['epar']
          : null,
        id_usuario: btoa(this.appStorage.getUserData().id),
        id_parceiro_nacional: this.appStorage.getUserData().isCrossBorder ? null : this.appStorage.getUserData().partnerId,
        id_parceiro_internacional: this.appStorage.getUserData().isCrossBorder ? this.appStorage.getUserData().partnerId : null,
        consultor: epar_consultor,
        inscricao_estadual: params.inscricao_estadual ? 'sim' : (this.appStorage.getUserData().stateRegistration ? 'sim' : 'não'),
      });
    } else {
      this.pushTag({
        event_name: params.event_name ? params.event_name : null,
        event: params.event,
        cnpj: params.cnpj ? params.cnpj : null,
        msg_de_erro: params.msg_de_erro ? params.msg_de_erro : null,
        tipo_de_cadastro: params.tipo_de_cadastro ? params.tipo_de_cadastro : null,
        cargo: params.cargo ? params.cargo : null,
        seller: params.seller ? params.seller : null,
        chave: this.route.snapshot.queryParams['chave']
          ? this.route.snapshot.queryParams['chave']
          : null,
        epar: this.route.snapshot.queryParams['epar']
          ? this.route.snapshot.queryParams['epar']
          : null,
        id_usuario: null,
        id_parceiro_nacional: null,
        id_parceiro_internacional: null,
        consultor: epar_consultor,
        inscricao_estadual: params.inscricao_estadual ? params.inscricao_estadual : null,
      });
    }

    /* datalayer GA */
  }
}
