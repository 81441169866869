import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-v3',
  template: '<html></html>',
})
export class V3Component {
  constructor(private route: ActivatedRoute) {
    let url,
      event_name,
      event = null;
    this.route.params.subscribe((params) => {
      url = decodeURIComponent(params.section);
      if (url.includes('?')) {
        const httpParams = new HttpParams({ fromString: url.split('?')[1] });
        url = httpParams.get('url').replace(/\|/gi, '/');
        event_name = httpParams.get('event_name');
        event = httpParams.get('event');
      }
    });
    window.location.href = url;
  }
}
