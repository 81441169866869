import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { AppGlobalService } from '../../app.service';
import { HeaderTranslate } from './header.translate';
declare const $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [HeaderTranslate],
})
export class HeaderComponent implements OnInit {
  isMobileOpen = false;
  isMobileLangOpen = false;
  isXborder: boolean;
  pageLanguage: string;
  translate = {};
  languages = [
    { lang: 'pt', text: 'PT', title: 'Acesse o site em Português', route: '/' },
    { lang: 'en', text: 'EN', title: 'Access the site in English', route: '/internacional' },
    { lang: 'zh', text: '中文', title: 'Access the site in Chinese', route: '/internacional/zh' },
  ];

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private appService: AppGlobalService,
    private headerTranslate: HeaderTranslate
  ) {
    this.pageLanguage = this.appService.getPageLanguage(this.activeRoute.snapshot);
    this.isXborder = this.pageLanguage != 'pt';
    this.translate = this.headerTranslate[this.pageLanguage];
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      event instanceof NavigationStart && this.removeMobileStyles();
    });
  }

  addMobileStyles() {
    this.isMobileOpen = true;
    $('body').addClass('menu-mobile-body');
  }

  removeMobileStyles() {
    this.isMobileOpen = false;
    $('body').removeClass('menu-mobile-body');
  }

  toggleMobileMenu() {
    this.isMobileOpen = !this.isMobileOpen;
    this.isMobileOpen ? this.addMobileStyles() : this.removeMobileStyles();
  }

  reloadAppByLanguage(url: string) {
    document.location.href = url;
  }

  downloadApp() {
    const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    if (/android/i.test(userAgent)) {
      window.open('https://play.google.com/store/apps/details?id=com.b2wdigital.bonmarketplace&hl=pt_BR', '_blank');
      return;
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) {
      window.open('https://itunes.apple.com/br/app/b2w-marketplace/id1189319909?mt=8', '_blank');
      return;
    }
    window.open('https://play.google.com/store/apps/details?id=com.b2wdigital.bonmarketplace&hl=pt_BR', '_blank');
  }
}
