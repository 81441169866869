import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderTranslate } from '../header.translate';
import { HeaderTranslateModel } from '../header.interface';
import { AppGlobalService } from '../../../app.service';
import { GoogleAnalyticsService } from '../../../google-analytics.service';
import { environment } from '../../../../environments/environment';
declare const $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  @Input() isMobileOpen: boolean;
  @Output() removeMobileStyles = new EventEmitter();
  isXborder = false;
  translate: HeaderTranslateModel;
  navItems = [];
  pageLanguage: string;
  linkGetRegisterUrl: string = '/v3/registre-se';
  linkGetLoginUrl: string = '/login';

  constructor(
    private activeRoute: ActivatedRoute,
    private appService: AppGlobalService,
    private headerTranslate: HeaderTranslate,
    private gaService: GoogleAnalyticsService
  ) {
    /* jscpd:ignore-start */
    this.pageLanguage = this.appService.getPageLanguage(this.activeRoute.snapshot);
    this.isXborder = this.pageLanguage != 'pt';
    this.translate = this.headerTranslate[this.pageLanguage];
    /* jscpd:ignore-end */
    this.navItems = [
      {
        text: this.translate.forYourBusiness,
        id: 'para_o_seu_negocio_header',
        title: this.translate.forYourBusiness,
        scrollId: 'vender',
        event: 'header_links',
        event_name: 'header_para_seu_negocio',
      },
      {
        text: this.translate.howToSell,
        id: 'como_vender_header',
        title: this.translate.howToSell,
        scrollId: 'how-to-selling',
        event: 'header_links',
        event_name: 'header_como_vender',
      },
      {
        text: this.translate.doubts,
        id: 'duvidas_header',
        title: this.translate.doubts,
        scrollId: 'doubts',
        event: 'header_links',
        event_name: 'header_duvidas',
      },
    ];
  }

  getLoginUrl(): string {
    const path = {
      default: '/login',
      xborder: '/login/internacional/' + this.pageLanguage,
    };
    const userUrl = this.isXborder ? path.xborder : path.default;
    this.linkGetLoginUrl = environment.production ? environment.oldHost + userUrl : userUrl;
    return this.linkGetLoginUrl;
  }

  getRegisterUrl(): string {
    const path = {
      default: '/v3/registre-se',
      xborder: '/international/onboard/start-register?language=' + this.pageLanguage,
    };
    const userUrl = this.isXborder ? path.xborder : path.default;
    this.linkGetRegisterUrl = environment.production ? environment.oldHost + userUrl : userUrl;
    return this.linkGetRegisterUrl;
  }

  clickGa(params) {
    this.gaService.ga({
      event: params.event,
      event_name: params.event_name,
    });
  }

  scrollToElement(element, time = 500) {
    {
      let top = 110;
      if (this.isMobileOpen) {
        top = 80;
        this.removeMobileStyles.emit();
      }

      setTimeout(() => {
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $(`#${element}`).offset().top - top,
          },
          time
        );
      }, 100);
    }
  }
}
