import { V3Component } from './pages/v3/v3.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UtilsModule } from './utils/utils.module';
import { PagesModule } from './pages/pages.module';
import { PageNotFoundComponent } from './utils/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./login/login.module').then((m) => m.LoginModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then((m) => m.LoginModule) },
  { path: 'new', component: V3Component },
  { path: 'new/:section', component: V3Component },
  {
    path: 'nubmetrics',
    loadChildren: () => import('./nubmetrics-auth/nubmetrics-auth.module').then((m) => m.NubmetricsAuthModule),
  },
  // keep in last...
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), UtilsModule, PagesModule],
  exports: [RouterModule],
})
export class AppRoutes {}
