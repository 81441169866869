const i18n = {
  pt: {
    go: 'Ir ',
    goTo: 'Ir para ',
    goTo_: 'Ir para o',
    externalLink: ' (link externo)',
    relationshipWithInvestors: 'Relações com investidores',
    linkTitle: 'Americanas Marketplace - Homepage link',
    logoDescription: 'logo Americanas Marketplace',
    brand: 'Logo',
    about: {
      title: 'Sobre',
      whoWeAre: 'Quem somos',
      investors: 'Investidores',
      careers: 'Carreiras',
      terms: 'Termos',
    },
    discover: {
      title: 'Descubra',
      blackfriday: 'Black Friday 2023',
      blog: 'Blog',
      richMaterials: 'Materiais ricos',
      privacyPolicies: 'Políticas de privacidade',
      buyForYourCompany: 'Compre para a sua empresa',
      securityGuide: 'Guia de Segurança',
    },
    community: {
      title: 'Comunidade',
      help: 'Ajuda',
    },
    ourWebsites: {
      title: 'Nossos sites',
    },
    socialMedia: {
      title: 'Redes sociais',
    },
    allRightsReserved: 'Todos os direitos reservados',
    address: 'Endereço',
    stateRegistration: 'Inscrição Estadual',
  },
  en: {
    go: 'Go ',
    goTo: 'Go to ',
    goTo_: 'Go to',
    externalLink: ' (external link)',
    relationshipWithInvestors: 'Investors relationship',
    linkTitle: 'Americanas Marketplace - Homepage link',
    logoDescription: 'logo Americanas Marketplace',
    brand: 'Logo',
    about: {
      title: 'About',
      investors: 'Investor relations',
      terms: 'Service Terms',
    },
    discover: {
      title: 'Discover',
      howToSell: 'How to sell',
    },
    community: {
      title: 'Commutnity',
      help: 'Help',
    },
    ourWebsites: {
      title: 'Our sites',
    },
    socialMedia: {
      title: 'Follow us',
    },
    allRightsReserved: 'Todos os direitos reservados',
    address: 'Endereço',
    stateRegistration: 'Inscrição Estadual',
  },
  zh: {
    go: 'Go ',
    goTo: 'Go to ',
    goTo_: 'Go to',
    externalLink: ' (external link)',
    relationshipWithInvestors: 'Investors relationship',
    linkTitle: 'Americanas Marketplace - Homepage link',
    logoDescription: 'logo Americanas Marketplace',
    brand: 'Logo',
    about: {
      title: '关于',
      investors: '投资者关系',
    },
    discover: {
      title: '发现',
      howToSell: '如何销售',
    },
    community: {
      title: '社群',
      help: '帮助',
    },
    ourWebsites: {
      title: '我们的网站',
    },
    socialMedia: {
      title: '关注我们',
    },
    allRightsReserved: 'Todos os direitos reservados',
    address: 'Endereço',
    stateRegistration: 'Inscrição Estadual',
  },
};
export type I18nPT = typeof i18n.pt;
export type I18nEN = typeof i18n.en;
export type I18nZH = typeof i18n.zh;
export class FooterTranslate {
  pt = i18n.pt;
  en = i18n.en;
  zh = i18n.zh;
}
