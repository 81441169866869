import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FooterTranslate, I18nEN, I18nPT, I18nZH } from './footer.translate';
import { AppGlobalService } from '../../app.service';
import { GoogleAnalyticsService } from '../../google-analytics.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [FooterTranslate],
})
export class FooterComponent {
  isXborder = false;
  translate: I18nPT | I18nEN | I18nZH;
  pageLanguage: string;

  constructor(
    private appService: AppGlobalService,
    private activeRoute: ActivatedRoute,
    private footerTranslate: FooterTranslate,
    private gaService: GoogleAnalyticsService
  ) {
    this.pageLanguage = this.appService.getPageLanguage(this.activeRoute.snapshot);
    this.isXborder = this.pageLanguage != 'pt';
    this.translate = this.footerTranslate[this.pageLanguage];
  }

  sendAnalyticsData(eventName: string) {
    const gaData = {
      event: 'footer_links',
      event_name: eventName,
      utm_source: 'site_americanas_marketplace',
      utm_medium: 'footer',
      utm_campaign: 'americanas_marketplace',
    };
    this.gaService.ga(gaData);
  }
}
