import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppStorageService {
  private userData: any;
  private userStorageName: string;

  constructor() {
    this.userStorageName = 'sessionState';
  }

  getUserData() {
    return this.userData ? this.userData : this.getLocalStorage(this.userStorageName);
  }

  addUserData(_attribute, _value) {
    const userData = this.getUserData();
    userData[_attribute] = _value;
    this.setUserData(userData);
  }

  setUserData(_userData) {
    if (!_userData) {
      return;
    }
    _userData.user = _userData.id;
    this.setLocalStorage(this.userStorageName, _userData);
  }

  setUserPartnerData(_partnerList) {
    const userData = this.getUserData();
    userData.partnerList = _partnerList;
    userData.companyId = environment.idCia;
    userData.partnerName = _partnerList[0].name;
    userData.partnerId = _partnerList[0].id;
    this.setUserData(userData);
  }

  removeUserData() {
    this.removeLocalStorage(this.userStorageName);
  }

  private getLocalStorage(_storageName) {
    this.userData = JSON.parse(localStorage.getItem(_storageName));
    return this.userData || {};
  }

  private setLocalStorage(_storageName, _userData) {
    this.removeLocalStorage(_storageName);
    localStorage.setItem(_storageName, JSON.stringify(_userData));
  }

  private removeLocalStorage(_storageName) {
    this.userData = null;
    localStorage.removeItem(_storageName);
  }
}
